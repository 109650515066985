import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/header/header'
import Footer from '../components/footer/footer'


const WpPost = ({data}) => {
	const pageData = JSON.parse(data.wpPage.blocksJSON);
	const images = data.allWpMediaItem.edges;

  return (
	<div>
		<Layout data={data.wpPage.seo}></Layout>
		<div><header class="pageHeader regHero-module--pageHeader--b82fd regHero-module--pageHeaderAlt--1810f" id="page-header"><div class="container"><div class="col col-xs-1-1"><div><h1 class="regHero-module--pageHeaderTitle--399db">404: Page not found</h1><p class="regHero-module--breadcrumbs--343a3"><span><span><a href="/">Home</a> &gt;<span><span class="regHero-module--breadcrumb_last--a7b46" aria-current="page">404: Page not found</span></span></span></span></p></div></div></div></header></div>
		<Footer data={data.wp.acfOptionsFooter.footerOptions} popup={data.wp.acfOptionsHeader}></Footer>
	</div>
  )
}

export const query = graphql`
	query {
	  wpPage(uri: {eq: "/"}) {
	  id
	  title
	  blocksJSON
	  hero {
		heroContent
		heroButtonText
		heroButtonLink {
		  url
		}
		heroImage {
		  sourceUrl
		  altText
		}
		heroMobileImage {
		  sourceUrl
		}
	  }
	  seo {
		breadcrumbs {
		  text
		  url
		}
		fullHead
	  }
	}

	wp {
		acfOptionsHeader {
			popup {
			  popupTitle
			  popupText
			  popupLinkText
			  popupLink {
					url
				}
			  popupImage {
					sourceUrl
				}
			}
		}
	  themeGeneralSettings {
		ratingsWidget {
		  ratingName
		  ratingNumber
		  ratingStars
		  ratingValue
		  ratingLink
		}
	  }
	  acfOptionsFooter {
		footerOptions {
		  copyrightMessage
		  facebook
		  fieldGroupName
		  footerLogo {
			sourceUrl
		  }
		  instagram
		  tagline
		  tiktok
		}
	  }
	}

	allWpMediaItem {
	  edges {
		node {
		  id
		  sourceUrl
		  databaseId
		}
	  }
	}
  }
`

export default WpPost